import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import scrollTo from "gatsby-plugin-smoothscroll"

const Header = ({ siteTitle }) => (
  <header>
    <div className="container has-ptb-5">
      <Link to="/" className="logo">
        <img
          src={"/images/logo.png"}
          alt="Digitally Inklined Logo"
          width="40"
        />
      </Link>

      <div className="nav">
        <Link to="/services">Services</Link>
        <Link to="/work">Work</Link>
        <a href="javascript:;" onClick={() => scrollTo("#contact")}>
          Contact
        </a>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
