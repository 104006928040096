/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import { FaFacebookSquare, FaInstagram, FaWhatsapp } from "react-icons/fa"

import Header from "./header"
import "./layout.css"
import { GiStoneThrone } from "react-icons/gi"
import { PhotoPlaceholder } from "react-placeholder-image"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div className="container has-mb-5">
        <main>{children}</main>
      </div>

      <div className="container">
        <div className="grid-1-2 gap-0" id="contact">
          <div
            className="has-padding-1 has-bg-light align-content-vcenter"
            data-sal="slide-up"
            data-sal-delay="200"
            data-sal-easing="ease"
            data-sal-duration="900"
          >
            <span className="icon" style={{ color: "rebeccapurple" }}>
              <GiStoneThrone />
            </span>
            <h2 className="has-txt-bold has-mb-5">
              We love coffee & conversations.
            </h2>
            <p className="has-mb-5">
              We at <strong>Digitally Inklined</strong> love meeting new people and discussing ideas. Drop by our
              office, or drop a line at{" "}
              <a href="mailto:info@digitallyinklined.com">
                info@digitallyinklined.com
              </a>
              .
            </p>
            <span className="social">
              {/* <a target="_blank" href="https://api.whatsapp.com/send?phone=919646498849&text=Hi!%20I%27m%20Interested%20In%20Calista%20Homes%20At%20Pushpak%20Nagar,%20Navi%20Mumbai.%20Please%20Share%20Details.">
                <FaWhatsapp />
              </a> */}
              <a
                target="_blank"
                href="https://www.facebook.com/digitalllyinklined/"
              >
                <FaFacebookSquare />
              </a>
              <a
                target="_blank"
                href="https://www.instagram.com/digitallyinklined/"
              >
                <FaInstagram />
              </a>
            </span>
          </div>
          <div
            data-sal="slide-up"
            data-sal-delay="200"
            data-sal-easing="ease"
            data-sal-duration="900"
          >
            <PhotoPlaceholder className="is-fit" width={600} height={600} />
          </div>
        </div>
      </div>

      <footer className="container has-ptb-5">
        <div>
          © {new Date().getFullYear()}, All Rights Reserved.
          {` `}
          Digitally Inklined
        </div>
        {/* <div>
          <a target="_blank" href="javascript:;">
            <FaFacebookSquare />
          </a>
          <a target="_blank" href="javascript:;">
              
          </a>
        </div> */}
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
